export enum PageRoutes {
  ACCOUNT = '/account',
  BUILD_SCHEDULE = '/build-schedule',
  CALENDAR_PREVIEW = '/calendar/preview',
  HOLIDAYS_ADD = '/holidays/add',
  HOLIDAYS_EDIT = '/holidays/edit',
  HOLIDAYS_LIST = '/holidays/list',
  LANDING_PAGE = '/',
  LOGIN = '/login',
  OTP = '/otp',
  PARENT_INFO = '/build-schedule/parent-info',
  PROFILE = '/account/profile',
  SCHEDULE_DETAILS = '/build-schedule/schedule-details',
  SECURITY = '/account/security',
  SIGNUP = '/signup',
  TEMPLATES = '/schedule/templates',
}

export enum RouteIds {
  ACCOUNT = 'routes/account',
  BUILD_SCHEDULE = 'routes/build-schedule',
  CALENDAR = 'routes/calendar',
  HOLIDAYS = 'routes/holidays',
}

export const accountRouteRegex = new RegExp(/^\/account\/?$/);
export const scheduleRouteRegex = new RegExp(/^\/schedule\/?$/);
export const buildScheduleRouteRegex = new RegExp(/^\/build-schedule\/?$/);
export const calendarRouteRegex = new RegExp(/^\/calendar\/?$/);
export const holidaysRouteRegex = new RegExp(/^\/holidays\/?$/);
